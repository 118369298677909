import { StyledButton, StyledMenu } from "./style";
import { KeyboardArrowDown, PersonOutlineOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeStorage } from "../../utils";
import { logout } from "../../keycloak";
import { APP, HOME, LOGIN } from "../../Constants/api.constant";
import { useLocation } from "react-router-dom";
import logoutImg from "../../assets/images/logout.svg";
import emailIMG from "../../assets/images/Email.svg"
import "./style/userAccount.css"
import { localStorageGetItem } from "../../../src/utils/Storage/storageUtils";

const UserAccount = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    removeStorage();
    if (window?.IS_SSO_ENABLED === "TRUE") {
      logout();
    } else {
      navigate(LOGIN);
      setAnchorEl(null);
    }
  };
  const userid = localStorageGetItem('emailId');

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <StyledButton
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        isdarkheaderrequired={(
          location?.pathname === APP || location?.pathname === HOME
        ).toString()}
      >
        <PersonOutlineOutlined />
      </StyledButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        <ul className="dropdown-menu-head">
          <li className="menu-opt d-flex align-center">
            <div className="img-box">
              <img height={"14px"} alt="" src={emailIMG} />
            </div>
            <div className="text-box">{userid}</div>
          </li>
          <li onClick={handleLogout} className="menu-opt d-flex align-center">
            <div className="img-box">
              <img height={"14px"} alt="" src={logoutImg} />
            </div>
            <div className="text-box">Sign Out</div>
          </li>
        </ul>
      </StyledMenu>
    </>
  );
};
export default UserAccount;
