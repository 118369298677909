import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { localStorageSetItem, localStorageGetItem, localStorageRemoveItem} from "../utils/Storage/storageUtils";

export const storeToken = (data) => {
  const decoded = jwtDecode(data?.access_token);
  if (decoded.email) {
   localStorageSetItem("exp", decoded.exp);
   localStorageSetItem("iat", decoded.iat);
   localStorageSetItem("refresh_token", `${data?.refreshToken}`);
   localStorageSetItem("access_token", `${data?.access_token}`);
   localStorageSetItem("access_token", `${data?.access_token}`);
   localStorageSetItem("authServerId", `${decoded.sub}`);
   localStorageSetItem("userId", `${decoded.sub}`);
   localStorageSetItem("emailId", `${decoded.email}`);
   localStorageSetItem("userName", `${decoded.preferred_username}`);
  }
};

export const getAccessToken = localStorageGetItem("access_token");
//
export const getAuthServerId = localStorageGetItem("authServerId");

export const removeStorage = () => {
  localStorageRemoveItem("exp");
  localStorageRemoveItem("iat");
  localStorageRemoveItem("refresh_token");
  localStorageRemoveItem("access_token");
  localStorageRemoveItem("access_token");
  localStorageRemoveItem("authServerId");
  localStorageRemoveItem("userId");
  localStorageRemoveItem("emailId");
  localStorageRemoveItem("userName");
  return true;
};

export const rememberUser = (objUser) => {
  localStorageSetItem("remember_user", JSON.stringify(objUser));
};

export const getRememberUser = () => {
  let data = localStorageGetItem("remember_user")
    ? JSON.parse(localStorageGetItem("remember_user"))
    : null;
  return data;
};

export const removeRemember = () => {
  let data = localStorageGetItem("remember_user");
  if (data) localStorageRemoveItem("remember_user");
};

export const validateText = (text) => {
  const value = text && text?.length > 0 ? text.replace(/^\s+/, "") : "";
  toast.dismiss();

  if (value?.length === 0) {
    toast.error("Please provide the prompt");
    return false;
  } else if (/^\s+/.test(value) || !/^[a-zA-Z0-9\s]*$/.test(value)) {
    toast.error("Please enter valid text to search");
    return false;
  } else {
    return true;
  }
};

export const getSearchedHistory = () => {
  const data = localStorageGetItem("sketchSearchHistory");

  return data ? JSON.parse(localStorageGetItem("sketchSearchHistory")) : [];
};

export const setSearchedHistory = (searchedInput) => {
  const searchHistory = getSearchedHistory();
  const index = searchHistory?.indexOf(searchedInput?.toLocaleLowerCase());

  if (index !== -1) {
    searchHistory?.splice(index, 1);
  } else if (searchHistory?.length === 5) {
    searchHistory?.pop();
  }
  searchHistory?.unshift(searchedInput?.toLocaleLowerCase());
  localStorageSetItem("sketchSearchHistory", JSON.stringify(searchHistory));
};

function base64ToBlob(base64, mimeType) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

export function downloadBase64File(base64, fileName) {
  try {
    const link = document.createElement("a");
    const mimeType = "image/png";
    const blob = base64ToBlob(base64, mimeType);
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.log("downloadBase64File error: ", error);
  }
}
