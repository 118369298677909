const APP_CONST = {
    SOMETHING_WENT_WRONG : "Something went wrong",
    COMPOSE_BATCH_RAN_SUCCESSFULLY : "Compose batch ran successfully",
    JOB_IS_RUNNING : "Job is running. Please check Results after 10 mins.",
    DELETED_SUCCESSFULLY : "Deleted sucessfully",
    CHAT_SESSION_DELETED : "Chat session deleted",
    SPACE_NOT_ALLOWED : "Space not allowed in input field",
    COPIED_TO_CLIPBOARD : "Copied to clipboard",
    PLEASE_ENTER_YOUR_MESSAGE : "Please enter your message",
    ONLY_SPACES_SPECIAL_CHAR_NOT_ALLOWED : "Only spaces or special character is not allowed.",
    PLEASE_ENTER_CHAT_NAME : "Please enter chat name",
    PLEASE_ENTER_CHAT_NAME_WITHOUT_ANY_LEADING_SPACES : "Please enter chat name without any leading spaces",
    CHAT_SESSION_SUCCESSFULLY_CREATED : "Chat session successfully created",
    PROMPT_CREATED_SUCCESSFULLY : "Prompt created successfully",
    PROMPT_UPDATED_SUCCESSFULLY : "Prompt updated successfully",
    PROMPT_SAVED_SUCCESSFULLY : "Prompts saved successfully",
    DECORATOR_API_CALL_FAILED : "DecoratorApi call failed",
    PROMPT_DELETED : "Prompt deleted",
    SPACE_NAME_ID_USER_ID_REQ : "Space name , Space Id and User Id  are required for file embeding.",
    TRAINING_SUCCESSFULLY_COMPLETED : "Training successfully completed",
    TRAINING_FAILED : "Training Failed",
    MODEL_CATALOGS_LIST_EMPTY : "Model catalogs list empty.",
    GET_MODEL_CATALOGS_LIST_FAILED : "Get model catalogs list faild.",
    PROMPTS_UPLOADED_SUCCESSFULLY : "Prompts uploaded successfully",
    LOGIN_SUCCESSFUL : "Login successful",
    PLEASE_ENTER_VALID_TEXT : "Please Enter Valid Text",
    UNSUPPORTED_CONTENT_TYPE : "Unsupported Content type",
    SELECT_ONLY_TXT_FILE : "Select only .txt file",
    OOPS_SOMETHING_WENT_WRONG : "Oops! Something went wrong",
    START : "start",
    STOP : "stop",
    ERROR : "error",
    SUCCESS : "success",
    WARNING : "warning",
    PLEASE_ENTER_TEXT_TO_CHECK_SENTIMENTS : "Please enter text to check sentiments"
}

export default APP_CONST;