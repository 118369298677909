import "./Prompts.css";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { getChat } from "../../Services/chat.service.js";
import PaperPlane from "../../assets/images/paper-plane.png";
import BouncingDotsLoader from "./BouncingDotsLoader.jsx";
import UserIcon from "../../assets/images/User_Icon.svg";
import Group2337 from "../../assets/images/Group2337.svg";
import aiicon from "../../assets/images/ai-icon.png";
import { CHAT_LITE_OPEN } from "../../Constants/api.constant.js";
import { logGAPageView, scrollChatToBottom } from "../../utils/Helper.js";
import { toastNotify } from "../../Container/toastServices.jsx";
import tooltip from "../../assets/images/tooltip.svg";
import { chatInputSchema } from '../../Schema/index.jsx';
import APP_CONST from "../../Constants/app.constant.js";

const ChatLiteOpen = (props) => {
  logGAPageView(CHAT_LITE_OPEN, "CHATLITEOPEN");

  const [isLoader, setIsLoader] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [metaCollection, setMetaCollection] = useState([]);
  const [sessionConversation, setSessionConversation] = useState([]);
  const [index, setIndex] = useState(0);
  const [hidden, setHidden] = useState(true);

  const queryParams = new URLSearchParams(window.location.search); 
  let spaceName = queryParams.get("spaceName");
  let userId = queryParams.get("userId");
  const title = queryParams.get('title');
  const desc = queryParams.get('desc');
  document.title = "Canvas AI " + (title ? ("- " + title) : "");
  if(spaceName?.includes("?")){
    spaceName = spaceName.split("?")[0];
  }

  const intialChatInputValues = {chatInput: null};
  const {
    errors,
    touched,
    handleChange,
    handleBlur,
    isValid
  } = useFormik({
    initialValues: intialChatInputValues,
    validationSchema: chatInputSchema,
    onSubmit: () => {
      sendRequest();
    },
  });

  const formatCopiedPrompt = (copiedPrompt) => {
    if (Array.isArray(copiedPrompt)) {
      return copiedPrompt
        .map((item) => {
          return `${item.role}: ${item.content}`;
        })
        .join("\n"); // Use a newline character as a separator.
    } else {
      return copiedPrompt;
    }
  };

  // ? User textarea onChange
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    handleChange(event);
  };

  // ? Send userRequest
  const sendRequest = () => {
    if(isValid)
    handleSendMessage(inputValue, "", true);
  };

  // exactQuestion
  const exactQuestion = (data, isFalse) => {
    if(isValid)
    handleSendMessage(data, "", isFalse);
  };

  // ? Handle enter press
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && isValid) {
      event.preventDefault();
      handleSendMessage(event.target.value, "", true);
    }
  };

  const createContext = () => {
    const originalArr =
      sessionConversation.length > 5
        ? sessionConversation.slice(-5)
        : sessionConversation;
    return originalArr?.map((item) => ({
      session_conversations: `Question: '${item?.userRequest}'\n Answer:'${item?.chatResponse[0]?.chatResponse}'\n`,
    }));
  };

  // ? Get Bot Response
  const handleSendMessage = async (msg, hint, isTrue) => {
  if (msg?.length === 0 || msg?.trim() === "") {
      toastNotify(APP_CONST.PLEASE_ENTER_YOUR_MESSAGE,APP_CONST.ERROR);
   }
   else{
    setInputValue("");
    setIsLoader(true);
    const meta_info = {};
    if (metaCollection?.length > 0) {
      metaCollection.forEach(({ tagTitle, tagValue }) => {
        meta_info[JSON.stringify(tagTitle)] = tagValue;
      });
    }

    const payload = {
      query: msg,
      temperature: undefined,
      prompts: hint,
      sessionId: undefined,
      type: Array.isArray(hint) ? "" : "chat",
      userRequest: msg,
      userId: userId ? userId :"e46005b2-8be2-461e-a000-477faa0e4d6d",
      isConversational: isTrue,
      embedding_metadata: meta_info,
      space_name: spaceName,
      context: createContext(),
    };

    const request = {
      userRequest: msg,
      chatResponse: [],
      index: index
    };

    setIndex(index+1);
    setSessionConversation((prevsessionConversation) => [
      ...prevsessionConversation,
      request
    ]);

    try {
      const response = await getChat(payload);
      const botMessage = response.data.response;
      const botResponse = {
        userRequest: msg,
        chatResponse: [
          {
            chatResponse: response.data.response,
            downloadLink: response.data.download_link,
            source: response.data.source,
            responseSource: response.data.responseSource,
            executionTime: response.data.execution_time,
            questions: response.data.questions,
          },
        ],
      };
      setSessionConversation((prevsessionConversation) => [
        ...prevsessionConversation.slice(0, index),
         botResponse,
         ...prevsessionConversation.slice(index+1)
       ]);
    } catch (error) {}
    setIsLoader(false);
   }
  };

  const getFileName = (data) => {
    const examplestring = data;
    const parts = examplestring.split("/");
    const lastPart = parts[parts.length - 1];
    return lastPart;
  };
  // ** Method to scroll chat to bottom
  useEffect(() => {
    scrollChatToBottom();
  });
  useEffect(() => {
    handleSendMessage("Hi, how can you help me. Suggest me some Frequently asked questions", "", true);
  }, []);
  return (
    <div className="prompt-tab-content">
    <div className="prompt-tab-inner-div" >
     <img className="prompt-tab-img" src={Group2337} alt="logo"/> 
     {
      title ? (<div className="title-info" onMouseEnter={() => setHidden(false)} onMouseLeave={() => setHidden(true)}> | {title}
      <img className="info-icon" src={tooltip} alt="info"/> 
      { hidden ? null : <div className="desc-info">{desc}</div>}
      </div>) : <></>
     }
     </div>
          <div className="chat-lite-window">
            <div className="chatwindow" id="chatWindow">
              {sessionConversation?.map((message, index) => (
                <>
                  <div className="flex-box flex-row-reverse gap-2">
                      <span className="d-flex align-center justify-center"> 
                       <img src={UserIcon} className="aiicon" alt="aiicon"/>
                       </span> 
                    <div className="user-block text-end">
                      {Array.isArray(message?.userRequest)
                        ? formatCopiedPrompt(message?.userRequest)
                        : message?.userRequest}
                    </div>
                  </div>
                  {message?.chatResponse.length > 0 ? (
                    <div className="flex-box gap-2">
                    <div className="py-3">
                    <img
                      src={aiicon}
                      alt="responseicon"></img>
                    </div>
                      <div className="bot-block set-html">
                       

                    <div className="img-data">
                      <div>{message?.chatResponse[0]?.chatResponse}</div>
                    </div>

                    {message?.chatResponse[0]?.downloadLink?.length > 0 &&
                      message?.chatResponse[0]?.downloadLink[0] !== "" && (
                        <div className="response-padding ">
                          <h6 className="sourceTitle">Source Document(s):</h6>
                          {message?.chatResponse[0]?.downloadLink?.map(
                            (d, i) => (
                              <p className="mb-1">
                                {i + 1 + ")"} {getFileName(d)}
                              </p>
                            )
                          )}
                        </div>
                      )}

                    {message?.chatResponse[0]?.questions.length > 0 && (
                      <>
                        <h6 className="sourceTitle">
                          {message?.chatResponse[0].chatResponse.length > 0 && (
                            <>You can also ask.</>
                          )}
                          {message?.chatResponse[0].chatResponse.length < 1 && (
                            <>Did you mean?</>
                          )}
                        </h6>

                        {message?.chatResponse[0]?.questions.map((d, i) => (
                          <p className=" mb-1">
                            {i + 1 + ")"}{" "}
                            <a
                              className="linkText"
                              onClick={(e) => {
                                exactQuestion(d, true);
                              }}>
                              <u>{d}</u>
                            </a>
                          </p>
                        ))}
                        {message?.chatResponse[0].chatResponse.length < 1 && (
                          <p className="">
                            {message?.chatResponse[0]?.questions?.length +
                              1 +
                              ")"}{" "}
                            <a
                              className="linkText"
                              onClick={(e) => {
                                exactQuestion(message?.userRequest, false);
                              }}>
                              <u>
                                No, I would like to see the response of my
                                question only.
                              </u>
                            </a>
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ) : null}
            </>
          ))}

              {isLoader ? (
                <div className="flex-box gap-2">
                  <div className="py-3">
                    <img className="loaderImg" src={aiicon} alt="aiicon"></img>
                  </div>
                  <div className="bot-block">
                    <BouncingDotsLoader />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="chat-input-block">
          <form id="chatInputForm" onSubmit={sendRequest}>
            <textarea
              type="text"
              className="chat-input-garuda"
              id="chatInput"
              name="chatInput"
              placeholder="Ask Canvas"
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onKeyPress={handleKeyPress}
            />
            <div className={`chatbot-send-button ${!isValid ? 'disabled' : ''}`} onClick={sendRequest}>
              <img src={PaperPlane} alt=""></img>
            </div>
          {
          errors?.chatInput && touched?.chatInput ? (
            <div className="form-error">{errors?.chatInput}</div>
            ) : null
            }
          </form>
          </div>
        </div>
  );
};
export default ChatLiteOpen;
