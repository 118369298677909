import React, { useState } from "react";
import "./LandingPage.css";
import { useNavigate } from "react-router-dom";
import { APP } from "../../Constants/api.constant";
import Header from "./Header";
import Home from "./Home";
import MarketPlaces from "./MarketPlaces";

const LandingPage = (props) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(props.tabValue);
  return (
    <div className="landingPage">
      <Header tab={tab} setTab={setTab} />
      {tab === 0 && <Home />}
      {tab === 1 && navigate(APP)}
      {tab === 2 && <MarketPlaces />}
    </div>
  );
};

export default LandingPage;
