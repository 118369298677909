import { Grid } from "@mui/material";
import "./MarketPlaces.css";
import { logGAPageView } from "../../utils/Helper";
import BFSImage from "../../assets/images/BFSImage.png";
import EAUImage from "../../assets/images/EAUImage.png";
import healthCareImage from "../../assets/images/healthCareImage.png";
import hitechImage from "../../assets/images/hitechImg.png";
import insuranceImage from "../../assets/images/insuranceImg.png";
import lifesceinceImage from "../../assets/images/lifesceinceImg.png";
import MEImage from "../../assets/images/MEImage.png";
import MFGImage from "../../assets/images/MFGImage.png";
import RCGImage from "../../assets/images/RCGImage.png";
import TTHImage from "../../assets/images/TTHImage.png";

const MarketPlaces = () => {
  const data = [
    {
      id: 0,
      href: "/bfs",
      name: "Banking and Financial Services",
      image: BFSImage,
    },
    {
      id: 1,
      href: "/hc",
      name: "HEALTHCARE",
      image: healthCareImage,
    },
    {
      id: 2,
      href: "/ls",
      name: "LIFE SCIENCES",
      image: lifesceinceImage,
    },
    {
      id: 3,
      href: "/mfg",
      name: "Manufacturing ",
      image: MFGImage,
    },
    {
      id: 4,
      href: "/tth",
      name: "Travel Transport and Hospitality",
      image: TTHImage,
    },
    {
      id: 5,
      href: "/me",
      name: "Media & Entertainment",
      image: MEImage,
    },
    {
      id: 6,
      href: "/eu",
      name: "Energy and Utilities",
      image: EAUImage,
    },
    {
      id: 7,
      href: "/rcg",
      name: "Retail and Consumer Goods",
      image: RCGImage,
    },
    {
      id: 8,
      href: "/ins",
      name: "Insurance",
      image: insuranceImage,
    },
    {
      id: 9,
      href: "/hh",
      name: "Hitech",
      image: hitechImage,
    },
  ];

  return (
    <div className="marketPlacesDiv">
      <span className="marketPlacesHeader">Marketplace</span>
      <Grid className="marketPlacesContainer" container gap={3}>
        {data?.map((item) => {
          return (
            <Grid
              item
              md={3}
              sx={{
                backgroundImage: `url(${item.image})`,
              }}
              className="marketPlacesContentCard"
              onClick={() => {
                logGAPageView(item.href, item.name);
                window.open(item.href, "_blank");
              }}
            >
              <div className="marketPlacesInnerContent d-flex align-center justify-center">
                <div className="marketPlacesInnerContentBorder">
                  <div className="marketPlacesFontColor">{item.name.toUpperCase()}</div>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default MarketPlaces;
