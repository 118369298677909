import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import ReactGA from "react-ga4";
import { lazy, Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { localStorageGetItem } from "./utils/Storage/storageUtils";

import Loader from "./Components/Loader";
import {
  LOGIN,
  APP,
  HOME,
  ULTIMA_BOT_SIMULATOR,
  BATCH_COMPOSE,
  PLAY_AREA,
  SKETCH,
  AZURE_VOICE_BOT,
  GOOGLE_VOICE_BOT,
  VOICE_TRANSLATOR,
  SENTIMENT_ANALYSIS,
  STREAM_LITE,
  META_TAG,
  CHAT_PAGE,
  CHAT_LITE_OPEN,
  CHAT_LITE,
} from "./Constants/api.constant";
import Header from "./Components/Header";
import { useMediaQuery, useTheme } from "@mui/material";
import ChatLiteOpen from "./widgets/ChatLiteOpen/ChatLiteOpen";
import ChatLite from "./widgets/Chatlite/Chatlite";
import LandingPage from "./Components/LandingPage/LandingPage";

const LoginPage = lazy(() => import("./Components/LoginPage/LoginPage"));

const SentimentAnalysis = lazy(() =>
  import("./Components/SentimentAnalysis/sentimentanalysis")
);

const UltimaBotSimulator = lazy(() =>
  import("./Components/Chatwindow/ultimaBotSimulator")
);

const ChatPageComponent = lazy(() =>
  import("./Components/chatPage/ChatPageComponent")
);

const PlayArea = lazy(() => import("./Components/PlayArea/playarea"));

const Sketch = lazy(() => import("./Components/Sketch"));

const AzureVoiceBot = lazy(() => import("./Components/VoiceBot/azureVoiceBot"));

const GoogleVoiceBot = lazy(() =>
  import("./Components/VoiceBot/googleVoiceBot")
);

const VoiceTranslator = lazy(() =>
  import("./Components/VoiceBot/voiceTranslator")
);

const Navlanding = lazy(() => import("./Components/NavigationApp/navlanding"));

const BatchCompose = lazy(() =>
  import("./Components/BatchRunner/BatchCompose")
);

const Streamlit = lazy(() => import("./Components/NavigationApp/streamlit"));

const MetaTag = lazy(() => import("./Components/NavigationApp/metatag"));

function App() {
  if (window?.IS_GA_ENABLED === "TRUE")
    ReactGA.initialize(window?.GA_MEASUREMENT_KEY);

  const [showNav, setShowNav] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  useEffect(() => {
    setShowLogo(window.location.href.includes("/app"));
  }, [showLogo]);

  const tokenValid = () => {
    const now = Date.now() / 1000;
    const expiry = localStorageGetItem("exp") + localStorageGetItem("iat");
    return now < expiry;
  };
  
  return (
    <div className={`main-container ${showNav ? "showNav" : ""}`}>
      <ToastContainer autoClose={500} pauseOnFocusLoss={false} />
      <div
        className={`content-area-wrap ${
          location?.pathname === APP || location?.state?.tabValue === 2
            ? "darkBG"
            : location?.pathname === HOME
            ? "darkSolidBG"
            : ""
        }`}
      >
        {location?.pathname !== LOGIN &&
          location?.pathname !== HOME &&
          location?.pathname !== APP &&
          !location?.pathname?.includes(CHAT_LITE_OPEN) &&
          !location?.pathname?.includes(CHAT_LITE) && (
            <Header isMdDown={isMdDown} />
          )}
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path={LOGIN}
              element={
                window?.IS_SSO_ENABLED === "TRUE" ? (
                  <Navigate to={APP} replace />
                ) : (
                  <LoginPage />
                )
              }
            />
            <Route
              path={HOME}
              element={
                <LandingPage tabValue={location?.state?.tabValue || 0} />
              }
            />
            <Route path={APP} element={<Navlanding />} />
            <Route path={CHAT_PAGE} element={<ChatPageComponent />} />
            <Route
              path={ULTIMA_BOT_SIMULATOR}
              element={<UltimaBotSimulator />}
            />
            <Route path={BATCH_COMPOSE} element={<BatchCompose />} />
            <Route path={PLAY_AREA} element={<PlayArea />} />
            <Route path={SKETCH} element={<Sketch />} />
            <Route path={AZURE_VOICE_BOT} element={<AzureVoiceBot />} />
            <Route path={GOOGLE_VOICE_BOT} element={<GoogleVoiceBot />} />
            <Route path={VOICE_TRANSLATOR} element={<VoiceTranslator />} />
            <Route path={SENTIMENT_ANALYSIS} element={<SentimentAnalysis />} />
            <Route path={STREAM_LITE} element={<Streamlit />} />
            <Route path={META_TAG} element={<MetaTag />} />
            <Route path={CHAT_LITE_OPEN} element={<ChatLiteOpen />} />
            <Route path={CHAT_LITE} element={<ChatLite />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
