import { Box, styled } from "@mui/material";

const shouldForwardPropPath = (prop) => prop !== "path";

export const StyledBoxHeader = styled(Box, {
  shouldForwardProp: shouldForwardPropPath,
})(({ path }) => ({
  display: "flex",
  alignItems: !path ? "end" : "flex-start",
  justifyContent: "space-between",
  flexDirection: !path ? "column" : "row",
  padding: "10px 19px",
}));
