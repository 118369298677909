import { Backdrop, CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <Backdrop
      open
    >
      <CircularProgress thickness={8} sx={{
        color: "#c6e814"
      }}/>
    </Backdrop>
  );
};
export default Loader;
