export const localStorageGetItem = (key) => {
    return localStorage.getItem(key)
};

export const localStorageSetItem = (key,value) => {
    return localStorage.setItem(key,value)
}

export const localStorageRemoveItem = (key) => {
   return localStorage.removeItem(key)
}

export const localStorageClear = () => {
   return localStorage.clear();
}
