import React from "react";
import aiChipIcon from "../../assets/images/ai-chip.svg";
import flexibleIcon from "../../assets/images/flexible.svg";
import intuitiveIcon from "../../assets/images/intuitive.svg";
import scalableIcon from "../../assets/images/scalable.svg";
import secureIcon from "../../assets/images/secure.svg";
import slider from "../../assets/images/slider.svg";
import staticCreator from "../../assets/images/staticCreator.png";
import awsImg from "../../assets/images/aws.png";
import azureImg from "../../assets/images/azure.png";
import nvidiaImg from "../../assets/images/nvidia.png";
import ibmImg from "../../assets/images/ibm.png";
import { useNavigate } from "react-router-dom";
import { APP } from "../../Constants/api.constant";
import "./Home.css";
import { API_BASE_URL } from "../../Configuration/ServerConfig.js";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="authenticateContainer d-flex flex-column align-items-start">
      <div className="botIcon">
        <div className="canvasTitle d-flex flex-column justify-center">
          <h1 className="canvasTitleTop">
            Unlock <br /> the{" "}
          </h1>
          <h1 className="canvasTitleBottom">
            power <br /> of AI
          </h1>
          <h5 className="canvasDescription">
            Swiftly transform your API-driven ideas into production, with
            unmatched flexibility, accelerated development, and versatile
            cloud-native operations.
          </h5>
        </div>
        <div>
          <div className="scroll-icon">
          </div>
          <div className="scrollAnimateIcon">
            <div className="exploreImg"></div>
          </div>
        </div>
      </div>
      <div className="canvasFeaturesDiv">
        <div className="row canvasFeaturesRow m-0">
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={flexibleIcon} />
                </span>
                <span className="canvasFeatureName">Flexible</span>
              </div>
              <span className="canvasFeatureDesc">
                The canvas.ai consumption layer offers extensive customization
                options to cater to a wide range of business needs and use case
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={aiChipIcon} />
                </span>
                <span className="canvasFeatureName">Responsible AI</span>
              </div>
              <span className="canvasFeatureDesc">
                The security guard rails and proactive governance ensure
                ethical, transparent and compliant AI usage
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={secureIcon} />
                </span>
                <span className="canvasFeatureName">Secure</span>
              </div>
              <span className="canvasFeatureDesc">
                The platform prioritizes data security and fair usage by
                budgeting tokens, rate limiting and reporting
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={intuitiveIcon} />
                </span>
                <span className="canvasFeatureName">Intuitive</span>
              </div>
              <span className="canvasFeatureDesc">
                An intuituve user interface enables ease-of-use in building and
                using solutions in a low-code, no-code environment
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={scalableIcon} />
                </span>
                <span className="canvasFeatureName">Scalable</span>
              </div>
              <span className="canvasFeatureDesc">
                Battle tested for speed-to-scale, the platorm promises quick
                response time for all queries
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="videoDiv d-flex justify-center">
        <video
          className="videoContainer"
          muted
          loop
          playsInline
          controls
          autoPlay
        >
          <source src={`${API_BASE_URL}/video`} type="video/mp4"></source>
        </video>
      </div>
      <div className="navAppsDesc d-flex justify-center align-items-center flex-column">
        <span className="navAppsDescSpan">
          All your AI powered navigator applications and copilots in one place,
          curated and neatly categorized.
        </span>
        <div
          className="creator-apps-button"
          onClick={() => {
            navigate(APP);
          }}
        >
          <span>Go To Gallery</span>
        </div>
      </div>
      <div className="abstractBG">
        <div className="d-flex flex-row">
          <div className="buildKitApplicationSlider">
            <img src={slider} alt="slider" />
          </div>
          <div className="buildKitApplication d-flex flex-column justify-content-around">
            <div className="buildKitApplicationHeader">
              <div className="buildKitApplicationTitle">
                Want to build your own AI Application?
              </div>
              <div className="buildKitApplicationDescription">
                The canvas.ai offers extensive customization options to cater to
                a wide range of business needs and use case
              </div>
            </div>
            <div className="d-flex flex-row align-items-center">
              <div className="buildKitApplicationPoints">
                <div className="buildKitApplicationPoint">
                  &#8226; Create a Space & select one or more AI models that
                  suits your use case
                </div>
                <div className="buildKitApplicationPoint">
                  &#8226; Go to Space settings, and setup your RAG pipeline
                </div>
                <div className="buildKitApplicationPoint">
                  &#8226; Map your contents folder to the Space, and start
                  Indexing & Embedding
                </div>
                <div className="buildKitApplicationPoint">
                  &#8226; Use the prompt studio and check response to your
                  queries
                </div>
                <div className="buildKitApplicationPoint">
                  &#8226; Use the Evaluator to thoroughly test the responses
                </div>
                <div className="buildKitApplicationPoint">
                  &#8226; Go to Navigator Apps page, Create an App and map this
                  newly created space.
                </div>
                <div className="buildKitApplicationPoint">
                  &#8226; Assign users to publish the new Navigator App!
                </div>
              </div>
              <div className="buildKitApplicationPointsImage">
                <img src={staticCreator} alt="static Creator" />
              </div>
            </div>
            <a
              className="creator-apps-button d-flex justify-center align-items-center buildKitApplicationButton"
              href="/studio"
              onClick={() => {
                window.location.href = "/studio";
                window.location.reload(true);
              }}
            >
              <span>Creator Studio</span>
            </a>
          </div>
        </div>
        <div className="partnerSection d-flex flex-column">
          <div className="partnerSectionHeader">
            How are our partners powering Canvas.ai?
          </div>
          <div className="partnerSectionContent">
            <div className="row m-0">
              <div className="col-1 m-0"></div>
              <div className="col-4 m-0 partnerSectionContentDiv">
                <img src={awsImg} className="awsImg" />
                <span className="partnerSectionContentDetails">
                  As early access partners for AWS’s Generative AI services,
                  Canvas.ai is built, hosted and entirely served on top of AWS
                </span>
              </div>
              <div className="col-1 m-0"></div>
              <div className="col-4 m-0 partnerSectionContentDiv">
                <img src={azureImg} className="azureImg" />
                <span className="partnerSectionContentDetails">
                  Most powerful models Azure OpenAI and Azure Cognitive services
                  are powering voice to text, and LLM usecases in Canvas.ai
                </span>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-1 m-0"></div>
              <div className="col-4 m-0 partnerSectionContentDiv">
                <img src={nvidiaImg} className="nvidiaImg" />
                <span className="partnerSectionContentDetails">
                  Nvidia’s GPUs and NIMS Inference Services are powering the LLM
                  models you are using in Canvas.ai. Nvidia Guardrails provides
                  the a freeform moderation for the incoming prompts and the
                  responses from LLMs
                </span>
              </div>
              <div className="col-1 m-0"></div>
              <div className="col-4 m-0 partnerSectionContentDiv">
                <img src={ibmImg} className="ibmImg" />
                <span className="partnerSectionContentDetails">
                  IBM’s WatsonX Governance is powering and amplifying
                  Canvas.ai’s LLM Response and Model testing components
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="partnerSectionFooter d-flex">
        Your next big idea's just one click away.
        <div className="d-flex flex-column justify-center align-items-center contactUs">
          <span className="contactUsText">Contact Us</span>
          <p className="mailSection m-0 d-flex">
            <span className="material-symbols-outlined mailIcon me-2">
              mail
            </span>
            <a href="mailto:canvas.ai@ltimindtree.com" className="p-0 m-0">
              canvas.ai@ltimindtree.com
            </a>
          </p>
        </div>
      </div>
      <div className="copyRightSection d-flex justify-center w-100">
        © 2024 LTIMindtree Limited
      </div>
    </div>
  );
};

export default Home;
