import React from "react";
import "./LandingPage.css";
import Logo from "../../assets/branding-image/canvas_ai_creator_studio_logo.svg";
import UserAccount from "../UserAccount";

const Header = (props) => {
  return (
    <div className="authenticateHeader">
      <div className="logo-wrap">
        <img src={Logo} alt="canvas logo" />
      </div>
      <div className="tabs-section">
        <ul className="inner-tabs">
          <li
            data-toggle="tab"
            className={`inner-tab-item${props.tab === 0 ? " active" : ""}`}
            onClick={() => props.setTab(0)}
          >
            Home
          </li>
          <li
            data-toggle="tab"
            className={`inner-tab-item${props.tab === 1 ? " active" : ""}`}
            onClick={() => props.setTab(1)}
          >
            Gallery
          </li>
          <li
            data-toggle="tab"
            className={`inner-tab-item${props.tab === 2 ? " active" : ""}`}
            onClick={() => props.setTab(2)}
          >
            Lab
          </li>
        </ul>
      </div>
      <div className="headerRightSection">
        <a href="/studio" target="_blank" className="headerRightSection-text">
          <span>Create Your Own App</span>
        </a>

        <div className="headerRightSection-icon">
          <UserAccount />
        </div>
      </div>
    </div>
  );
};

export default Header;
