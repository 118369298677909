import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { API_BASE_URL } from "../Configuration/ServerConfig";
import { KEYCLOAK } from "../Constants/api.constant.js";
import { toastNotify } from "../Container/toastServices.jsx";
import APP_CONST from "../Constants/app.constant.js";
import { localStorageGetItem } from "../utils/Storage/storageUtils";

export const API = axios.create({
  baseURL: API_BASE_URL,
});

API.interceptors.request.use(
  (config) => {
    const { url } = config;

    if (url.includes(KEYCLOAK)) {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }

    const token = localStorageGetItem("access_token");
    if (token && !url.includes(KEYCLOAK)) {
      config.headers["correlation-id"] = uuidv4();
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return error;
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { status, config } = error.response;

    // Handle 401 or 403 authorization error for ALL API response
    if (status === 401 || status === 403) {
      // return API.request(config);
      // console.log("response interceptor", status, error);
      toastNotify("User Unauthorized", APP_CONST.ERROR);
      return Promise.reject(error.response);
    }

    // Handle 500 internal server error for ALL API response
    if (status === 500) {
      // return error.response;
      toastNotify("Internal server error", APP_CONST.ERROR);
      return Promise.reject(error.response);
    }

    return Promise.reject(error.response);
  }
);
