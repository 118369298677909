import React from "react";
import ReactDOM from "react-dom/client";
import "../src/assets/css/animate.css";
import "bootstrap/scss/bootstrap.scss";
import "material-symbols";
import "./style.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { initKeycloak } from "./keycloak";

window?.IS_SSO_ENABLED === "TRUE"
  ? initKeycloak(() => {
      renderApp();
    })
  : renderApp();

function renderApp() {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
