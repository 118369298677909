// Login API
export const REAL_MS = "/realms";
export const CANVAS_AI = "/canvasai";
export const PROTOCOL = "/protocol";
export const OPENID_CONNECT = "/openid-connect";
export const TOKEN = "/token";
export const KEYCLOAK = "/keycloak";

// Landing Page API
export const USER = "/user";
export const NAVIGATOR_APPS = "/navigatorapps";
export const CHAT_SERVICE = "/chatservice";

export const AUTH_USER_ID = "?authServerId=";

// Chat Service API
export const CHAT_SESSION = "/chatSession";
export const HOME = "/";
export const APP = "/app";
export const SPACES = "/spaces";
export const SPACE = "/space";
export const LOG = "/log";
export const PROMPT = "/prompt";
export const PROMPTS = "/prompts";
export const SUB_PROMPTS = "/subprompts";
export const UPLOAD = "/upload";
export const DOWNLOAD = "/download";
export const CHAT = "/chat";
export const SAVE = "/save";
export const COMPLETION = "/completion";
export const SETUP = "/setup";
export const PROXY_CONTROLLER = "/proxycontroller";
export const CACHING = "/caching";
export const CREATE_EMBEDDINGS = "/createEmbeddings";
export const SKETCH_SERVICE = "/sketchservice";
export const GET_DIAGRAM = "/getDiagram";
export const SENTIMENT_SERVICE = "/sentimentservice";
export const SENTIMENT_SCORE = "/sentimentscore";
export const SENTIMENT_BASE_URL = "https://int-canvasai.ltimindtree.com";

// Batch Compose API
export const COMPOSE = "/compose";
export const FILES = "/files";
export const BATCH = "batch";
export const OUTPUT = "/output";
export const DOCUMENTS = "/documents";
export const RUN = "/run";
export const PROMPT_LIST = "/promptList";
export const WORKSPACE = "/workspace";
export const DOCUMENT = "/document";
export const OUTPUT_FILE_NAME = "&output_file_name=";
export const SPACE_ID = "?spaceId=";
export const AND_SPACE_ID = "&spaceId=";
export const SPACE_NAME = "?space_name=";
export const USER_NAME = "?user_name=";
export const USER_ID = "&userId=";
export const APP_ID = "&appId=";
export const CHAT_SESSION_ID = "?chatSessionId=";
export const SESSION_ID = "?sessionId=";
export const PROMPT_ID = "?promptId=";
export const SPACE_PARAMS = "?space=";
export const USER_PARAMS = "&user=";
export const COMPOSE_TYPE = "&compose_type=";
export const FIELD_ID = "&fileid=";

// Stream Lit
export const SWIFT_SCRIPT = "/swiftscript";
export const SWIFT_SCRIPT_META = "/swiftscriptmeta";

export const CONTROL_PLANE_SERVICE = "/controlplaneservice";

// SSO
export const SSO_REALM = "canvasai";
export const SSO_CLIENT_ID = "canvasai-chat";

//Routes constants

export const LOGIN = "/login";
export const CHAT_PAGE = "/chatpage";
export const ULTIMA_BOT_SIMULATOR = "/ultimabotsimulator";
export const BATCH_COMPOSE = "/batchcompose";
export const PLAY_AREA = "/playarea";
export const SKETCH = "/sketch";
export const AZURE_VOICE_BOT = "/azurevoicebot";
export const GOOGLE_VOICE_BOT = "/googlevoicebot";
export const VOICE_TRANSLATOR = "/voicetranslator";
export const SENTIMENT_ANALYSIS = "/sentimentanalysis";
export const STREAM_LITE = "/streamlit";
export const META_TAG = "/metaTag";

//Chat lite open
export const CHAT_LITE_OPEN = "/chatliteopen";

//Chatlite
export const CHAT_LITE = "/chatlite";
export const ULTIMA_BOT_SERVICE = "/ultimabotservice";
export const CANVAS_AI_CHAT = "/canvasai_chat";
