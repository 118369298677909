/* eslint-disable jsx-a11y/alt-text */
import { StyledBoxHeader } from "./style";
import UserAccount from "../UserAccount";
import { useLocation } from "react-router-dom";
import { APP, SKETCH } from "../../Constants/api.constant";
import "./index.css";
import CanvasAiLogo from "../../assets/branding-image/canvas_ai_creator_studio_logo_light.png";

import SketchLogo from "../../assets/images/sketch-logo.png";

const Header = ({ isMdDown }) => {
  const location = useLocation();
  const path = location?.pathname !== APP && true;
  const isSketchApp =
    location?.pathname && location?.pathname.startsWith(SKETCH);
  return (
    <>
      {isSketchApp ? (
        <div className="sketchID">
          <StyledBoxHeader path={path}>
            {path && <img src={SketchLogo} alt="SketchLogo" />}
            <UserAccount />
          </StyledBoxHeader>
        </div>
      ) : (
        <StyledBoxHeader path={path}>
          {path && (
            <img
              src={CanvasAiLogo}
              width={isMdDown ? "150px" : "200px"}
              alt="canvas ai logo"
            />
          )}
          <UserAccount />
        </StyledBoxHeader>
      )}
    </>
  );
};
export default Header;
