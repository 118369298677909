import { API } from "./base.service";
import {
  CHAT_SESSION,
  APP,
  LOG,
  PROMPT,
  SPACE_ID,
  USER_ID,
  APP_ID,
  CHAT_SESSION_ID,
  SESSION_ID,
  PROMPT_ID,
  SPACE,
  SPACE_PARAMS,
  CHAT_SERVICE,
  USER,
  UPLOAD,
  USER_PARAMS,
  PROMPTS,
  SAVE,
  DOWNLOAD,
  SPACE_NAME,
  SUB_PROMPTS,
  COMPLETION,
  CHAT,
  CONTROL_PLANE_SERVICE,
  SETUP,
  FILES,
  PROXY_CONTROLLER,
  CACHING,
  FIELD_ID,
  CREATE_EMBEDDINGS,
  AND_SPACE_ID,
  SKETCH_SERVICE,
  GET_DIAGRAM,
  SENTIMENT_SERVICE,
  SENTIMENT_SCORE,
  CANVAS_AI_CHAT,
  ULTIMA_BOT_SERVICE,
} from "../Constants/api.constant";

export const uploadChatHints = (payload) => {
  return API.post(`${CHAT_SERVICE}${PROMPTS}${SAVE}`, payload);
};

export const downloadPrompt = (spaceName) => {
  return API.get(
    `${CHAT_SERVICE}${PROMPTS}${DOWNLOAD}${SPACE_NAME}${spaceName}`
  );
};

export const getSubPromptsCompletion = (payload) => {
  return API.post(
    `${CHAT_SERVICE}${PROMPTS}${SUB_PROMPTS}${COMPLETION}`,
    payload
  );
};

export const getFileList = (spaceName, spaceId, userId) => {
  return API.get(
    `${CONTROL_PLANE_SERVICE}${SETUP}${FILES}${SPACE_NAME}${spaceName}${SPACE_ID}${spaceId}${USER_ID}${userId}`
  );
};

export const getSpaceDetails = (spaceId, userId) => {
  return API.get(
    `${CONTROL_PLANE_SERVICE}${SPACE}${SPACE_ID}${spaceId}${USER_ID}${userId}`
  );
};

export const createEmbeddings = (payload) => {
  return API.post(
    `${CONTROL_PLANE_SERVICE}${PROXY_CONTROLLER}${CREATE_EMBEDDINGS}`,
    payload
  );
};

export const embedFile = (spaceName, spaceId, userId) => {
  return API.get(
    `${CONTROL_PLANE_SERVICE}${SETUP}${FILES}${SPACE_NAME}${spaceName}${AND_SPACE_ID}${spaceId}${USER_ID}${userId}`
  );
};

export const getSketchOutput = (payload) => {
  return API.post(`${SKETCH_SERVICE}${GET_DIAGRAM}`, payload);
};

export const getSentimentScore = (payload) => {
  return API.post(`${SENTIMENT_SERVICE}${SENTIMENT_SCORE}`, payload);
};

export const getGenAIAnalysisResponse = (payload) => {
  return API.post(`${CHAT_SERVICE}${CHAT}`, payload);
};

export const clearSpacesCache = (spaceName, fileId) => {
  return API.delete(
    `${CONTROL_PLANE_SERVICE}${PROXY_CONTROLLER}${CACHING}${SPACE_PARAMS}${spaceName}${FIELD_ID}${fileId}`
  );
};

export const getSubPromptsChat = (payload) => {
  return API.post(`${CHAT_SERVICE}${PROMPTS}${SUB_PROMPTS}${CHAT}`, payload);
};

export const getChat = (payload) => {
  return API.post(`${CHAT_SERVICE}${CHAT}`, payload);
};

export const getChatlite = (payload) => {
  return API.post(`${ULTIMA_BOT_SERVICE}${CANVAS_AI_CHAT}`, payload);
};

export const createNewChatSession = (payload) => {
  return API.post(`${CHAT_SERVICE}${CHAT_SESSION}`, payload);
};

export const getChatList = (spaceId, userId, appId) => {
  return API.get(
    `${CHAT_SERVICE}${CHAT_SESSION}${SPACE}${USER}${APP}${SPACE_ID}${spaceId}${USER_ID}${userId}${APP_ID}${appId}`
  );
};

export const deleteChatSession = (chatId) => {
  return API.delete(
    `${CHAT_SERVICE}${CHAT_SESSION}${CHAT_SESSION_ID}${chatId}`
  );
};

export const getChatSessionDatawithID = (sessionId) => {
  return API.get(`${CHAT_SERVICE}${LOG}${SESSION_ID}${sessionId}`);
};

export const createPrompt = (payload) => {
  return API.post(`${CHAT_SERVICE}${PROMPT}`, payload);
};

export const updatePrompt = (promptId, payload) => {
  return API.put(`${CHAT_SERVICE}${PROMPT}${PROMPT_ID}${promptId}`, payload);
};

export const getPrompts = (spaceId) => {
  return API.get(`${CHAT_SERVICE}${PROMPT}${SPACE_ID}${spaceId}`);
};

export const deletePromptID = (promptId) => {
  return API.delete(`${CHAT_SERVICE}${PROMPT}${PROMPT_ID}${promptId}`);
};

export const uploadPrompt = (payload, spaceId, userId) => {
  return API.post(
    `${CHAT_SERVICE}${PROMPT}${UPLOAD}${SPACE_PARAMS}${spaceId}${USER_PARAMS}${userId}`,
    payload
  );
};
