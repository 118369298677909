import ReactGA from "react-ga4";
import { localStorageGetItem } from "./Storage/storageUtils";

export const isAdmin = () => {
  return localStorageGetItem("isAdmin") === "true";
};

export const getCurrentUser = () => {
  return localStorageGetItem("user_name");
};

export const dataHubParams = () => {
  const params = {};
  if (isAdmin()) {
    params.exclude_files = true;
    params.include_metainfo = false;
  } else {
    params.userName = getCurrentUser();
  }
  return params;
};

export const logGAPageView = (page, title) => {
  if (window?.IS_GA_ENABLED === "TRUE") {
    ReactGA.send({
      hitType: "pageview",
      page: page,
      title: title,
    });
  }
};

export const scrollChatToBottom = () => {
  if (document.getElementsByClassName("user-block").length !== 0) {
    document.getElementById("chatWindow").scrollTop =
      document.getElementById("chatWindow").scrollHeight -
      document.getElementsByClassName("user-block")[
        document.getElementsByClassName("user-block").length - 1
      ].clientHeight +
      200;
  }
}